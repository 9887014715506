/**
 * @generated SignedSource<<28aabf5e01bce48feec8015bbd989791>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEmailVerified",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPaid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticatedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUserNode",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthenticatedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUserNode",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdfe82ea41a6a1abdf553ed370c235f6",
    "id": null,
    "metadata": {},
    "name": "AuthenticatedQuery",
    "operationKind": "query",
    "text": "query AuthenticatedQuery {\n  currentUser {\n    id\n    email\n    isEmailVerified\n    isPaid\n    ...Header_user\n  }\n}\n\nfragment Header_user on CurrentUserNode {\n  id\n  email\n  firstName\n}\n"
  }
};
})();

node.hash = "46c2f6d68c412df151815e42d91925c2";

module.exports = node;
