import React, { useContext, useRef } from 'react';
const events = require('events');
const AUTH_STATE_CHANGE_EVENT = 'authStateChange';

/**
 * Authentication state is stored in local storage
 */
export class Authenticator { 
    constructor(){
        this.isLoggedIn = true;
        this.setIsLoggedIn = this.setIsLoggedIn.bind(this);
        this.addStateChangeListener = this.addStateChangeListener.bind(this);
        this.eventEmitter = new events.EventEmitter();
    }

    setIsLoggedIn(isLoggedIn){
        this.isLoggedIn = isLoggedIn;
        this.eventEmitter.emit(AUTH_STATE_CHANGE_EVENT, this.isLoggedIn);
    }

    addStateChangeListener(callback) {
        this.eventEmitter.addListener(AUTH_STATE_CHANGE_EVENT, callback);
        return () => this.eventEmitter.removeAllListeners(AUTH_STATE_CHANGE_EVENT);
    }
}


/**
 * Authentication context used by child components
 */
export const AuthenticatorContext = React.createContext(null);
export const useAuthenticator = () => {
    return useContext(AuthenticatorContext);
};

export const AuthenticationProvider = function({children}){
    const authenticatorRef = useRef(new Authenticator());

    return (
        <AuthenticatorContext.Provider value={authenticatorRef.current}>
            {children}
        </AuthenticatorContext.Provider>
    )
}