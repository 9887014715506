import React, { useEffect, useState, useRef } from 'react';
import { useRelayFramework } from "src/providers/RelayProvider";
import { commitMutation, graphql } from 'react-relay';

import ReactAudioPlayer from 'react-audio-player';
import HotKeys from 'react-hot-keys';
import Tooltip from '@material-ui/core/Tooltip';
import LabelButton from './LabelButton';
import SubmitButton from './SubmitButton';
import SkipModal from './SkipModal';
import moment from 'moment';
import './style.scss';


const ClaimRecordingSnippetMutation = graphql`
    mutation ReviewRecordingSnippets_ClaimRecordingSnippetMutation {
        claimRecordingSnippet(input:{}) {
			recordingSnippetReview{
                id
                recordingId
                recordingAudioUrl
                recordingSnippetAudioUrl
                voiceSampleAudioUrl
                startInRecording
            }
        }
    }
`;


const LabelRecordingSnippetMutation = graphql`
    mutation ReviewRecordingSnippets_LabelRecordingSnippetMutation($input: LabelRecordingSnippetMutationInput!) {
        labelRecordingSnippet(input:$input) {
            isSuccess
        }
    }
`;

const ReviewRecordingSnippets = function(){
	const { environment } = useRelayFramework();
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitOnHover, setIsSubmitOnHover] = useState(false);
    const [prevRecordingId, setPrevRecordingId] = useState(null);
    const [recordingSnippetReview, setRecordingSnippetReview] = useState(null);
    const [isRecordingSnippetPlayed, setIsRecordingSnippetPlayed] = useState(false);
    const [isVoiceSampleOrRecordingPlayed, setIsVoiceSampleOrRecordingPlayed] = useState(false);
    const [recordingSnippetLabel, setRecordingSnippetLabel] = useState(undefined);
    const recordingAudioRef = useRef(null);
    const voiceSampleAudioRef = useRef(null);
    const recordingSnippetAudioRef = useRef(null);
    const { 
        id, 
        recordingId, 
        recordingAudioUrl, 
        recordingSnippetAudioUrl, 
        voiceSampleAudioUrl,
        startInRecording
    } = recordingSnippetReview || {};
    const isNewRecording = prevRecordingId !== recordingId;

    const claimRecordingSnippet = () => {
        setIsLoading(true);
        commitMutation(environment, {
            mutation: ClaimRecordingSnippetMutation,
            onError(error){
                console.log(error);
            },
            onCompleted({claimRecordingSnippet}){
                setRecordingSnippetReview(claimRecordingSnippet.recordingSnippetReview);
                setIsRecordingSnippetPlayed(false);
                setRecordingSnippetLabel(undefined);
                setIsLoading(false);
            }
        });
    }

    const labelRecordingSnippet = (label) => {
        setIsLoading(true);
        commitMutation(environment, {
            mutation: LabelRecordingSnippetMutation,
            variables: {
                input: {
                    recordingSnippetReviewId: id,
                    recordingSnippetLabel: label
                }
            },
            onError(error){
                console.log(error)
            },
            onCompleted(res){
                setPrevRecordingId(recordingId)
                claimRecordingSnippet();
            }
        })
    }

    const togglePlay = (audioEl) => {
        audioEl.paused ? audioEl.play() : audioEl.pause();
    }

    useEffect(()=>{
        /**
         * Claim a recording snippet on mount
         */
        claimRecordingSnippet();
    }, [])
    
    if(!recordingSnippetReview){
        return <div className="ui compact message">
            <div className="header">
                All snippets have been labeled!
            </div>
            <p>There are no available recording snippets to label at this time. Please check back later.</p>
        </div>
    }

    const labelButtonsDisabled = !isRecordingSnippetPlayed || (isNewRecording && !isVoiceSampleOrRecordingPlayed);
    return (
        <div id='review-recording-snippets'>
            <div className='box'>
                <div className='audios-div'>
                    <div className='audio'>
                        <div className='title'>Recording ID: <span className={isNewRecording ? 'purple' : ''}>#{recordingId}</span></div>
                        <HotKeys
                            keyName="r"
                            onKeyDown={() => togglePlay(recordingAudioRef.current.audioEl.current)}/>
                        <ReactAudioPlayer 
                            className='audio-player'
                            ref={recordingAudioRef}
                            controls 
                            controlsList="nodownload"
                            src={recordingAudioUrl} 
                            preload="metadata"
                            onCanPlay={() => recordingAudioRef.current.audioEl.current.playbackRate = 1.25}
                            onPlay={() => setIsVoiceSampleOrRecordingPlayed(true)}/>
                    </div>
                    <div className='audio'>
                        <div className='title'>Sample from the teacher</div>
                        <HotKeys
                            keyName="t"
                            onKeyDown={() => togglePlay(voiceSampleAudioRef.current.audioEl.current)}/>
                        {voiceSampleAudioUrl
                            ? (
                                <ReactAudioPlayer 
                                    disabled={true}
                                    ref={voiceSampleAudioRef}
                                    className='audio-player'
                                    controls 
                                    controlsList="nodownload"
                                    src={voiceSampleAudioUrl} 
                                    preload="auto"
                                    onCanPlay={() => voiceSampleAudioRef.current.audioEl.current.playbackRate = 1.25}
                                    onPlay={() => setIsVoiceSampleOrRecordingPlayed(true)}/>
                            ) : null}
                    </div>
                </div>
                <div className='instructions-div'>
                    <p>
                        <u><b>Instructions:</b></u> Snippet will start autoplaying when you load the page.
                        Figure out if the speaker is the teacher, by listening to the teacher sample or to the recording.
                        Then, label the snippet accordingly. You can use 1,2,3,4,5,6 to select options below.
                    </p>
                    {/* <ul>
                        <dt>Explanations for labels</dt>
                        <dd>Teacher: A single speaker who is the teacher</dd>
                        <dd>Not teacher: A single speaker who is not the teacher</dd>
                        <dd>Group: Overlapping chatter</dd>
                        <dd>Silence: No audible speakers, chatter or external media</dd>
                        <dd>External Media: Music, audio or video that was played during the class</dd>
                    </ul> */}
                </div>
                <div className='audios-div'>
                    <div className='audio'>
                        <div className='title'>Snippet to evaluate (at {moment.utc(startInRecording*1000).format('HH:mm:ss')})</div>
                        <HotKeys
                            keyName="space"
                            onKeyDown={() => togglePlay(recordingSnippetAudioRef.current.audioEl.current)}/>
                        <ReactAudioPlayer 
                            ref={recordingSnippetAudioRef}
                            className='audio-player'
                            controls 
                            controlsList="nodownload"
                            src={recordingSnippetAudioUrl} 
                            preload="auto"
                            autoPlay={true}
                            onPlay={() => setIsRecordingSnippetPlayed(true)}/>
                    </div>
                </div>
                

                <div className="buttons-div">
                    <div className="label-buttons">
                        <LabelButton 
                            disabled={labelButtonsDisabled} 
                            onClick={() => setRecordingSnippetLabel(1)} 
                            isSelected={recordingSnippetLabel === 1}
                            label="teacher"
                            text={"Teacher"}
                            hotKeyName={"1"}
                            />

                        <LabelButton 
                            disabled={labelButtonsDisabled} 
                            onClick={() => setRecordingSnippetLabel(2)} 
                            isSelected={recordingSnippetLabel === 2}
                            label="student"
                            text={"Not teacher"}
                            hotKeyName={"2"}
                            />

                        <LabelButton 
                            disabled={labelButtonsDisabled} 
                            onClick={() => setRecordingSnippetLabel(3)} 
                            isSelected={recordingSnippetLabel === 3}
                            label="group"
                            text="Group work"
                            hotKeyName={"3"}
                            />

                        <LabelButton 
                            disabled={labelButtonsDisabled} 
                            onClick={() => setRecordingSnippetLabel(4)} 
                            isSelected={recordingSnippetLabel === 4}
                            label="silence"
                            text="Silence"
                            hotKeyName={"4"}
                            />

                        <LabelButton 
                            disabled={labelButtonsDisabled} 
                            onClick={() => setRecordingSnippetLabel(5)} 
                            isSelected={recordingSnippetLabel === 5}
                            label="media"
                            text="External Media"
                            hotKeyName={"5"}
                            />

                        <LabelButton 
                            disabled={labelButtonsDisabled} 
                            onClick={() => setRecordingSnippetLabel(null)} 
                            isSelected={recordingSnippetLabel === null}
                            label={null}
                            text={<>Does not fit in a<br/> single category</>}
                            hotKeyName={"6"}
                            />
                    </div> 
                </div>
            </div>

            <div className="skip-submit">
                <Tooltip 
                    title="You need to select a label to submit."
                    open={(recordingSnippetLabel == null) && (isSubmitOnHover)}
                    arrow
                    interactive>
                        <span
                        onMouseEnter={()=>setIsSubmitOnHover(true)}
                        onMouseLeave={()=>setIsSubmitOnHover(false)}>
                            <SubmitButton 
                                disabled={recordingSnippetLabel === undefined} 
                                onClick={() => labelRecordingSnippet(recordingSnippetLabel)} 
                                isLoading={isLoading}/>
                            <HotKeys
                                keyName="enter"
                                onKeyDown={() => recordingSnippetLabel === undefined || labelRecordingSnippet(recordingSnippetLabel)}/>
                        </span>
                </Tooltip>

            </div>

        </div>
    );
}

export default ReviewRecordingSnippets;