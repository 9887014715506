import React from 'react';
import {Oval} from 'react-loader-spinner'
import './style.scss'


const Splash = function(){
    return (
        <div id='splash-page'>
            <div id="splash-page__title">
                <img src={require("assets/images/teachfx-logo.png")} alt=""/>
            </div>
            <div id="splash-page__loader">
                <Oval
                    color="#0bf"
                    height={50}
                    width={50}
                />
            </div>
        </div>
    )
}



export default Splash;
