/**
 * @generated SignedSource<<d630be811ded7ecfeae90b5fa9dfcb8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "ClaimRecordingSnippetMutationPayload",
    "kind": "LinkedField",
    "name": "claimRecordingSnippet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RecordingSnippetReviewNode",
        "kind": "LinkedField",
        "name": "recordingSnippetReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recordingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recordingAudioUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recordingSnippetAudioUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "voiceSampleAudioUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startInRecording",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "claimRecordingSnippet(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewRecordingSnippets_ClaimRecordingSnippetMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReviewRecordingSnippets_ClaimRecordingSnippetMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "66cedfebf8fdae3d26543af4fbe9177e",
    "id": null,
    "metadata": {},
    "name": "ReviewRecordingSnippets_ClaimRecordingSnippetMutation",
    "operationKind": "mutation",
    "text": "mutation ReviewRecordingSnippets_ClaimRecordingSnippetMutation {\n  claimRecordingSnippet(input: {}) {\n    recordingSnippetReview {\n      id\n      recordingId\n      recordingAudioUrl\n      recordingSnippetAudioUrl\n      voiceSampleAudioUrl\n      startInRecording\n    }\n  }\n}\n"
  }
};
})();

node.hash = "f756bf457626463327d0894e40bd61de";

module.exports = node;
