import React, { useContext, useRef } from 'react';
import { Environment, RecordSource, Store } from 'relay-runtime';
import "regenerator-runtime/runtime";
import {
    RelayNetworkLayer,
    retryMiddleware,
    authMiddleware,
    urlMiddleware,
    cacheMiddleware,
    loggerMiddleware,
    perfMiddleware,
    errorMiddleware,
} from 'react-relay-network-modern';
import Cookies from 'js-cookie';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl || "";

/**
 * RelayFramework handles all actions related to communicating 
 * with the graphql endpoint in our API.
 */
export class RelayFramework {
    constructor(authentication) {
        this.debug = false;
        this.authentication = authentication;
        this.recordSource = new RecordSource();
        this.store = new Store(this.recordSource);
        this.network = new RelayNetworkLayer([
            this.__getCacheMiddleware(),
            this.__getUrlMiddleware(),
            this.debug ? loggerMiddleware() : null,
            this.debug ? errorMiddleware() : null,
            this.debug ? perfMiddleware() : null,
            this.__getRetryMiddleware(),
            this.__getCsrfMiddleware(),
            this.__getAuthMiddleware(),
        ])
        this.environment = new Environment({ 
            network: this.network, 
            store: this.store
        })
    }

    __getCacheMiddleware(){
        const middleware = cacheMiddleware({
            size: 100, // max 100 requests
            ttl: 900000, // 15 minutes
            clearOnMutation: true
        });
        return middleware;
    }

    __getUrlMiddleware(){
        const middleware = urlMiddleware({
            url: () => API_URL + "/graphql/",
            credentials: 'include',
        });
        return middleware;      
    }

    __getRetryMiddleware(){
        const middleware = retryMiddleware({
            fetchTimeout: 15000,
            retryDelays: (attempt) => Math.pow(2, attempt + 3) * 100,
            beforeRetry: ({
                forceRetry,
                abort,
                delay,
                attempt,
                lastError,
                req,
            }) => {
                if (attempt >= 5) {
                    abort();
                }
            },
            statusCodes: [500, 503, 504],
        })
        return middleware;
    }

    __getCsrfMiddleware(){
        return next => async (req) => {
            if(!Cookies.get("csrftoken")){
                await fetch(API_URL + "/csrf/", {
                    method: 'GET',
                    credentials: 'include',
                });
            }
            
            req.fetchOpts.headers["X-CSRFToken"] = Cookies.get("csrftoken");
            return next(req);
        }
    }

    __getAuthMiddleware(){
        const middleware = authMiddleware({
            allowEmptyToken: true,
            tokenRefreshPromise: () => {
                this.authentication.setIsLoggedIn(false);
            }
        })
        return middleware;
    }
}

export const RelayFrameworkContext = React.createContext();
export const useRelayFramework = () => {
    return useContext(RelayFrameworkContext);
};