/**
 * @generated SignedSource<<db4f410c9e803df592f1e3d10eebbc26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LabelRecordingSnippetMutationPayload",
    "kind": "LinkedField",
    "name": "labelRecordingSnippet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSuccess",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewRecordingSnippets_LabelRecordingSnippetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewRecordingSnippets_LabelRecordingSnippetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0f9752b568e71d1968c01775ef13cad9",
    "id": null,
    "metadata": {},
    "name": "ReviewRecordingSnippets_LabelRecordingSnippetMutation",
    "operationKind": "mutation",
    "text": "mutation ReviewRecordingSnippets_LabelRecordingSnippetMutation(\n  $input: LabelRecordingSnippetMutationInput!\n) {\n  labelRecordingSnippet(input: $input) {\n    isSuccess\n  }\n}\n"
  }
};
})();

node.hash = "3b1adb327843b80af6102ed14a06d2e4";

module.exports = node;
