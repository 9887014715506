import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import App from 'src/navigators/root';
import * as Sentry from '@sentry/browser';
import { createTheme } from '@material-ui/core/styles';
import Constants from 'expo-constants';
import 'src/primary.scss';

Sentry.init({
    enabled: Constants.expoConfig.extra.nodeEnv == 'production',
    release: Constants.expoConfig.extra.packageVersion,
    environment: Constants.expoConfig.extra.nodeEnv,
    dsn: Constants.expoConfig.extra.sentryDsn,
});

const theme = createTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#00bbff',
            contrastText: '#fff',
        }
    },
    zIndex:{
        drawer: 500
    },
    overrides: {
        MuiInput: {
            root:{
                border:'none',
            }
        },
        MuiDialog:{
            paper: {
                padding: '10px',
                minWidth:'400px'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "12px",
            }
        }
    },
});


const root = createRoot(document.getElementById('root'));
const RootComponent = function(){
    return(
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    )
}

root.render(<RootComponent/>);




