import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import Authenticated from './Authenticated'
import Anonymous from './Anonymous'
import { RelayFramework, RelayFrameworkContext } from 'src/providers/RelayProvider';
import { Authenticator, AuthenticatorContext } from 'src/providers/AuthenticatorProvider';

const App = function(){
    const authenticator = useRef(new Authenticator());
    const relayFramework = useRef(new RelayFramework(authenticator.current));
    const [isLoggedIn, setLoggedIn] = useState(true);

    /**
     * We listen to authentication state changes and modify isLoggedIn accordingly,
     * which determines whether we render Authenticated or Anonymous components below.
     */
    useEffect(()=>{
        return authenticator.current.addStateChangeListener(setLoggedIn);
    },[]);

    return (
        <AuthenticatorContext.Provider value={authenticator.current}>
            <RelayFrameworkContext.Provider value={relayFramework.current}>
                <Routes>
                    <Route path="*" element={isLoggedIn ? <Authenticated/> : <Anonymous/>}/>
                </Routes>
            </RelayFrameworkContext.Provider>
        </AuthenticatorContext.Provider>
    )
}


export default App;
