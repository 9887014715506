import React from 'react'
import OnboardingWrapper from 'src/navigators/Wrappers/OnboardingWrapper'
import ErrorBoundary from 'src/navigators/Wrappers/ErrorBoundary';
import Login from 'src/components/Login';

const Anonymous = function () {
    return (
        <OnboardingWrapper>
            <ErrorBoundary>
                <Login/>
            </ErrorBoundary>
        </OnboardingWrapper>
    )
}

export default Anonymous;