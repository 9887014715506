import React from 'react';
import './style.scss';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import {ThreeDots} from "react-loader-spinner";

const SubmitButton = function(props){
    const optionClasses = classNames({
        'submit-button': true,
    })

    return <Button 
            className={optionClasses} 
            variant="outlined" 
            disabled={props.disabled}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}>
             {props.isLoading 
					? <ThreeDots
						className="loader"
						color="darkgrey"
						height={5}
						width={50}
						/>
					: "Submit"}
        </Button>

}


export default SubmitButton;