import React from 'react'
import './style.scss'


const OnboardingSteps = function({stepIndex}){
    return (
        <div id='onboarding-steps'>
            <div className={`step${stepIndex < 0 ? ' step--inactive' : ''}`}>
                <div className='step__number'>
                    1
                </div>
                <div className='step__label'>
                    Create Account
            </div>
            </div>
            <div className={`step${stepIndex < 1 ? ' step--inactive' : ''}`}>
                <div className='step__number'>
                    2
                </div>
                <div className='step__label'>
                    Verify Email
            </div>
            </div>
            <div className={`step${stepIndex < 2 ? ' step--inactive' : ''}`}>
                <div className='step__number'>
                    3
                </div>
                <div className='step__label'>
                    Record Voice Sample
                </div>
            </div>
        </div>
    )
}

export default OnboardingSteps;