import React from 'react';
import './style.scss';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import HotKeys from 'react-hot-keys';

const LabelButton = function(props){
    const optionClasses = classNames({
        'label-button': true,
        [`label-button--${props.label}`]: true,
        [`label-button--selected--${props.label}`] : props.isSelected
    })


    return (
        <>
            <HotKeys
                keyName={props.hotKeyName}
                onKeyDown={() => props.disabled || props.onClick()}/>
            <Button 
                variant="outlined" 
                classes={{outlined: optionClasses }}
                disabled={props.disabled}
                onClick={props.onClick}>
            {props.text}
            </Button>
        </>
    )
}


export default LabelButton;