import React from 'react';
import OnboardingSteps from './OnboardingSteps'
import './style.scss'

const OnboardingWrapper = function({children}){
    let onboardingSteps = null;
    const {pathname, search} = window.location;
    if(pathname == '/signup'){
        onboardingSteps = <OnboardingSteps stepIndex={0}/>
    } else if(pathname == '/emailVerification' || (pathname == '/auth' && search.match("verifyEmail"))){
        onboardingSteps = <OnboardingSteps stepIndex={1}/>
    } else if(pathname == '/voice-sample'){
        onboardingSteps = <OnboardingSteps stepIndex={2}/>
    }
    return(
        <div id="onboarding-box">
            <div className="onboarding-title">
                <img src={require("assets/images/teachfx-logo.png")} alt="" />
            </div>
            <div className="onboarding-form-wrapper">
                {onboardingSteps}
                {children}
            </div>
        </div>
    )
}

export default OnboardingWrapper