import React, { useState } from 'react';
import { useRelayFramework } from "src/providers/RelayProvider";
import { commitMutation, graphql } from 'react-relay';
import TextField from '@material-ui/core/TextField';
import validator from 'validator';
import { ThreeDots } from 'react-loader-spinner'
import { useAuthenticator } from 'src/providers/AuthenticatorProvider';
import './style.scss'


/**
 * Graphql mutation for login
 */
const LoginUserMutation = graphql`
    mutation LoginUserMutation($input: LoginUserMutationInput!) {
        loginUser(input: $input) {
			clientMutationId
        }
    }
`;

const Login = function(){
	const { environment } = useRelayFramework();
	const authenticator = useAuthenticator();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [isErrorModalOpen, setErrorModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validator.isEmail(email)) {
			setErrorMessage('Please input a valid email.')
			setErrorModalOpen(true)
		} else if (password.length == 0) {
			setErrorMessage('Oops! You forgot to enter a password.')
			setErrorModalOpen(true)
		} else {
			setLoading(true);
			commitMutation(environment, {
				mutation: LoginUserMutation,
				variables:{
					input:{
						email: email.toLowerCase(),
						password
					},
				},
				onError(error){
					if(error.res){
						alert("Please provide valid credentials.")
					} else {
						alert("Network error. Please try again later.")
					}
					setLoading(false)
					setErrorModalOpen(true)
				},
				onCompleted(){
					authenticator.setIsLoggedIn(true)
				}
			});
		}
	}


	return (
		<div id="login-page">
			<form onSubmit={handleSubmit}>
				<div className="text-field-wrapper">
					<TextField
						fullWidth={true}
						placeholder='Email'
						type='email'
						name="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="text-field-wrapper">
					<TextField
						fullWidth={true}
						placeholder='Password'
						type='password'
						name="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				{
					isLoading
						? <ThreeDots
							className="loader"
							color="darkgrey"
							height={5}
							width={50}
						/>
						: <input
							className={"submit-button teachfx-background-color"}
							type="submit"
							value="LOG IN"
						/>
				}
				<br />
			</form>
		</div>
	)

}

export default Login;