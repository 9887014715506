import React from "react";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import RateReview from '@material-ui/icons/RateReview';
import Assessment from '@material-ui/icons/Assessment';

const useStyles = makeStyles({
	menuPaper: {
		width: '75px',
		background: '#43474E',
		color: 'white',
		overflow: 'hidden'
	},
	menuItem: {
		padding: '0',
	},

	menuLink: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		fontSize: '11px',
		alignItems: 'center',
		color: 'white',
		whiteSpace: 'normal',
		textAlign: 'center',
		padding: '8px',
		"&:hover": {
			color: 'white'
		}
	},
	menuIcon: {
		height: '30px',
		width: '30px',
		marginBottom: '10px'
	},
});


function Sidebar() {
	const styles = useStyles();

	return (
		<div>
			<Drawer
				className={styles.menuPaper}
				variant="permanent"
				classes={{ paper: styles.menuPaper, root: styles.menuRoot }}>
				<div style={{ height: '55px', minHeight: '55px' }} />
				<MenuItem className={styles.menuItem}>
					<div>
						<Link
							className={styles.menuLink} 
							to={'/review-recording-snippets'}>
							<RateReview/>
							<span>{"Review Recording Snippets"}</span>
						</Link>
						{/* <Link
							className={styles.menuLink} 
							to={'/quality-analysis'}>
							<Assessment/>
							<span>{"Quality Analysis"}</span>
						</Link> */}
					</div>
				</MenuItem>
			</Drawer>
		</div>
	);
}

export default Sidebar;